<template>
  <Header />
  <Filters v-on:filters-changed="updateFilters" />
  <EtsyFetcher
    v-bind:filters="filters"
    v-on:load-state-changed="updateLoadState"
    v-on:listing-data-found="updateListingData" />

  <div v-if="loading">
    <Spinner />
  </div>
  <div v-else>
    <ItemDisplay
      v-bind:itemName="listingData.itemName"
      v-bind:itemDescription="listingData.itemDescription"
      v-bind:url="listingData.url"
      v-bind:price="listingData.price"
      v-bind:currency="listingData.currency"
      v-bind:listingImages="listingData.listingImages" />
  </div>
  <Footer />
</template>

<script>
import EtsyFetcher from './components/EtsyFetcher.vue'
import ItemDisplay from './components/ItemDisplay.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Spinner from './components/Spinner.vue'
import Filters from './components/Filters.vue'

export default {
  name: 'App',
  components: {
    EtsyFetcher,
    ItemDisplay,
    Header,
    Footer,
    Spinner,
    Filters
  },
  data() {
    return {
      loading: true,
      listingData: { },
      filters: { }
    }
  },
  methods: {
    updateLoadState: function(loadState) {
      this.loading = loadState;
    },
    updateListingData: function(listingData) {
      this.listingData = listingData;
    },
    updateFilters: function(filters) {
      this.filters = {
        minPrice: filters.minPrice,
        maxPrice: filters.maxPrice,
        keywords: filters.keywords
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: darksalmon;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(900deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(1800deg);
  }
}

</style>
