<template>
    <div class="filter-box">
        <div class="filter-expansion" v-on:click="showHideFilters">
            <span class="filter-title">Filters <span v-if="filterCount > 0">({{filterCount}})</span></span>
            <div class="status-symbol">
                <span v-if="showFilters">+</span>
                <span v-else>-</span>
            </div>
        </div>
        <div class="filter-selection" v-if="!showFilters">
            <div class="filter-input">
                Min Price:
                <input placeholder="Min Price" v-model="minPrice" />
                <div class="error" v-if="minPriceError">{{ minPriceErrorText }}</div>
            </div>
            <div class="filter-input">
                Max Price:
                <input placeholder="Max Price" v-model="maxPrice" />
                <div class="error" v-if="maxPriceError">{{ maxPriceErrorText }}</div>
            </div>
            <div class="filter-input">
                Keywords:
                <input placeholder="Keywords" v-model="keywords" />
            </div>
            <div>
                <button class="clear-filters" v-on:click="clearFilters()">Clear Filters</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            minPrice: null,
            maxPrice: null,
            keywords: '',
            showFilters: true,
            minPriceError: false,
            minPriceErrorText: '',
            maxPriceError: false,
            maxPriceErrorText: '',
            filterCount: 0
        }
    },
    watch: {
        minPrice() {
            this.validateFilters();
        },
        maxPrice() {
            this.validateFilters();
        },
        keywords() {
            this.validateFilters();
        },
    },
    methods: {
        showHideFilters() {
            this.showFilters = !this.showFilters;
        },
        validateFilters() {
            this.resetFilterMessages();

            if (this.minPrice !== null && this.minPrice !== '' && isNaN(this.minPrice)) {
                this.minPriceError = true;
                this.minPriceErrorText = 'Min price must be numeric';
            }

            if (this.minPrice < 0) {
                this.minPriceError = true;
                this.minPriceErrorText = 'Min price must be greater than 0';
            }

            if (this.maxPrice !== null && this.maxPrice !== '' && isNaN(this.maxPrice)) {
                this.maxPriceError = true;
                this.maxPriceErrorText = 'Max price must be numeric';
            }

            if (this.maxPrice < 0) {
                this.maxPriceError = true;
                this.maxPriceErrorText = 'Max price must be greater than 0';
            }
            
            if (this.minPrice !== null 
                && this.maxPrice !== null 
                && this.minPrice !== ''
                && this.maxPrice !== ''
                && this.minPrice > this.maxPrice
                && this.minPriceError === false
                && this.maxPriceError === false) {
                this.minPriceError = true;
                this.maxPriceError = true;
                this.minPriceErrorText = 'Min price must not be greater than max price';
                this.maxPriceErrorText = 'Min price must not be greater than max price';
            }
            
            if (!this.minPriceError && !this.maxPriceError) {
                this.calculateFilterCount();
                this.emitFilterUpdates();
            }
        },
        emitFilterUpdates() {
            this.$emit('filters-changed', {
                minPrice: this.minPrice,
                maxPrice: this.maxPrice,
                keywords: this.keywords
            });
        },
        clearFilters() {
            this.minPrice = null;
            this.maxPrice = null;
            this.keywords = '';
            this.filterCount = 0;
            this.resetErrors();
        },
        calculateFilterCount() {
            this.filterCount = 0;

            if (this.minPrice !== null && this.minPrice !== '' && this.minPriceError == false) {
                this.filterCount++;
            }

            if (this.maxPrice !== null && this.maxPrice !== '' && this.maxPriceError == false) {
                this.filterCount++;
            }

            if (this.keywords !== null && this.keywords !== '') {
                this.filterCount++;
            }
        },
        resetFilterMessages() {
            this.filterCount = 0;

            this.minPriceError = false;
            this.maxPriceError = false;
            this.minPriceErrorText = '';
            this.maxPriceErrorText = '';
        }
    }
}
</script>

<style scoped>

.filter-expansion {
    cursor: pointer;
    text-align: left;
    color: salmon;
}

.filter-title {
    size: 16px;
    padding-left: 10px;
}

.status-symbol {
    float: right;
    size: 16px;
    padding-right: 10px;
}

.filter-box {
   border: 1px salmon solid;
   padding: 5px;
   margin: 15%;
   margin-top: unset;
   margin-bottom: unset;
}

.filter-selection {
    display: block;
}

.filter-input {
    margin-left: 20px;
    display: inline-grid;
    width: 250px;
}

.clear-filters {  
  margin: 10px;
  padding: 3px;
  color:darksalmon;
  background: none;
  border: 2px solid darksalmon;
  cursor: pointer;
}

.clear-filters:active {
  background: darksalmon;
  color: white;
}

.error {
    color: red;
}


@media screen and (max-width: 900px) {
    .filter-input {
        width: unset;
    }
}

</style>