<template>
    <div class="title">
        <h1 style="padding-left:30px;">Roulettesy!</h1>
    </div>
    <div class="spacer"></div>
</template>

<style scoped>
.title {
    text-align: start;
    background:darksalmon;
    color: white;
    height: 60px;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
}

.spacer {
    width: 100%;
    height: 60px;
}
</style>