<template>
    <div class="spacer"></div>
    <div class="footer">
        Created by <a href="https://www.joshh.co.uk" target="_blank">joshh</a>
        <div class="donation-image">
            <a :href="donationUrl" target="_blank">
                <img src="../assets/bmac.png" alt="Buy me a coffee" height="25"/>
            </a>
        </div>
    </div>
</template>

<script>

export default ({
    data() {
        return {
            donationUrl: ''
        }
    },
    mounted: function() {
        this.donationUrl = process.env.VUE_APP_BuyMeACoffee_Url;
    }
})
</script>


<style scoped>
.footer {
    background:darksalmon;
    padding: 10px;
    color: white;
    margin-top: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
}

.spacer {
    height: 30px;
}

a {
  color: #2e815b;
}

.donation-image {
    float: right;
    padding-right: 20px;
}

</style>