<template>
  <div>
    <button class="reroll" v-on:click="fetchItem" :disabled="loading">Something Else!</button>
  </div>
</template>

<script>
export default {
  props: {
    filters: { type: Object }
  },
  data() {
    return {
      itemName: '',
      itemDescription: '',
      url: '',
      price: 0,
      currency: '',
      listingImages: [],
      loading: false
    }
  },
  methods: {
    fetchItem: async function () {
      this.$emit('load-state-changed', true);
      this.loading = true;

      let page = Math.floor(Math.random() * 10001);

      const requestOptions = {
        'method': 'GET',
        'headers': {
          'x-api-key': process.env.VUE_APP_ETSY_API_Key,
        },
      };

      const response = await fetch(
        process.env.VUE_APP_API_URL + '/listings/active?limit=1&offset=' + page + this.getFilterString(),
        requestOptions
      );

      if (response.ok) {
        const data = await response.json();
        this.itemName = data.results[0].title;
        this.itemDescription = data.results[0].description;
        this.url = data.results[0].url;
        this.price = (data.results[0].price.amount / data.results[0].price.divisor).toFixed(2);
        this.currency = data.results[0].price.currency_code;
        
        await this.fetchListingImages(data.results[0].shop_id, data.results[0].listing_id)
      } else {
        this.resetData();
        console.log(`An error occurred when talking to the Etsy API: ${response.status}`);
      }

      let resultData = {
        itemName: this.itemName,
        itemDescription: this.itemDescription,
        url: this.url,
        price: this.price,
        currency: this.currency,
        listingImages: this.listingImages
      };

      this.$emit('load-state-changed', false);
      this.$emit('listing-data-found', resultData);
      this.loading = false;
    },
    fetchListingImages: async function (shopId, listingId) {      
      const requestOptions = {
        'method': 'GET',
        'headers': {
          'x-api-key': process.env.VUE_APP_ETSY_API_Key,
        },
      };

      const response = await fetch(
        process.env.VUE_APP_API_URL + '/shops/' + shopId + '/listings/' + listingId + '/images',
        requestOptions
      );

      if (response.ok) {
        const data = await response.json();
        this.listingImages = data.results;
      } else {
        this.resetData();
        console.log(`An error occurred when talking to the Etsy API: ${response.status}`);
      }
    },
    resetData: function () {
      this.itemName = '';
      this.itemDescription = '';
      this.url = '';
      this.price = 0;
      this.currency = '';
      this.listingImages = [];
    },
    getFilterString: function() {
      let filterString = '';

      if (this.filters.minPrice > 0) {
        filterString += '&min_price=' + this.filters.minPrice;
      }

      if (this.filters.maxPrice > 0) {
        filterString += '&max_price=' + this.filters.maxPrice;
      }

      if (this.filters.keywords && this.filters.keywords.length > 0) {
        filterString += '&keywords=' + this.filters.keywords;
      }
      
      return filterString;
    }
  },
  mounted: function() {
    this.fetchItem();
  }
}
</script>

<style scoped>

.reroll {
  width: 30%;
  margin: 10px;
  padding: 3px;
  color:darksalmon;
  background: none;
  border: 2px solid darksalmon;
  cursor: pointer;
}

.reroll:active {
  background: darksalmon;
  color: white;
}

</style>
