<template>
  <div class="item-display-area">      
    <div v-if="listingValid()">
        <div class="title"><h1 v-html="itemName"></h1></div>
        <div>
            <div class="image-area">
                <img class="etsy-image" :src="listingImages[0].url_fullxfull" />
            </div>
            <div class="text-area">
                <div class="description-box"><p v-html="itemDescription"></p></div>
                <h2>{{ price }} {{ currency }}</h2>
                <h2><a :href="url" target="_blank">View on ETSY</a></h2>
            </div>
        </div>
    </div>
    <div v-else>No item was found!</div>
  </div>
</template>

<script>

export default {
    props: {
        itemName: { Type: String },
        itemDescription: { Type: String},
        listingImages: { Type: Array },
        price: { Type: Number },
        currency: { Type: String },
        url: { Type: String }
    },
    methods: {
        listingValid() {
            return this.itemName 
                && this.itemDescription 
                && this.listingImages 
                && this.price 
                && this.currency 
                && this.url;
        }
    }
}
</script>

<style scoped>

a {
  color: #42b983;
}

.etsy-image {
  max-height: 500px;
}

.description-box {
    width: 400px;
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.image-area {
    display: inline-block;
    margin-right: 10px;
    min-width: 500px;
}

.text-area {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.title {
    padding-left: 10%;
    padding-right: 10%;
}

.item-display-area {
    min-height: 500px;
}

@media screen and (max-width: 900px) {
    .item-display-area {
        max-width: 350px;
    }

    .etsy-image {
        max-width: 350px;
    }

    .description-box {
        max-width: 350px;
        padding: unset;
    }

    .image-area {
        min-width: 0px;
        max-width: 350px;
        margin: 0px;
        padding: unset;
    }

    .title { 
        padding: unset;
    }
}

</style>